import React, { useEffect } from "react";
import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import * as layoutConstants from "../redux/slices/SettingSlice";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";

//External Lib Import

// Auth
const Login = React.lazy(() => import("../pages/Account/Login"));

//Page
const AdminDashboard = React.lazy(() =>
  import("../pages/Dashboard/AdminDashboard")
);
const ApproveStudent = React.lazy(() =>
  import("../pages/Admin/AdminDashboard")
);

const LogBook = React.lazy(() =>
  import("../pages/Dashboard/logbook")
);

const AdminExamarks = React.lazy(() =>
  import("../pages/Admin/ApproveExamamrks")
);

const HealthRecordsPage = React.lazy(() =>
  import("../pages/Dashboard/HealthRecord")
);
const staffhealthrecord = React.lazy(() =>
  import("../pages/staff/HealthRecord_Staff")
);
const ExamMarksPage = React.lazy(() =>
  import("../pages/Dashboard/viewExamamrks")
);
const AddExamMarksPage = React.lazy(() =>
  import("../pages/staff/AddExamamrks")
);
const StaffDashboard = React.lazy(() =>
  import("../pages/staff/StaffDashboard")
);
const Logout = React.lazy(() => import("../pages/Account/Logout"));

const LoadComponent = ({ component: Component }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return <Component />;
};

const AllRoutes = () => {
  const { LayoutType } = useSelector((state) => state.Setting);
  const { UserDetails, RoleDetails } = useSelector((state) => state.User);
  const { AccessToken } = useSelector((state) => state.Auth);

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (LayoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
console.log('RoleDetails',RoleDetails)
  if (AccessToken && UserDetails && RoleDetails === "STUDENT") {
    return (
      <Routes>
        <Route to="/" element={<Layout />}>
          <Route
            path="/dashboard"
            element={<LoadComponent component={AdminDashboard} />}
          />

          <Route
            path="/healthrecord"
            element={<LoadComponent component={HealthRecordsPage} />}
          />

          <Route
            path="/logbook"
            element={<LoadComponent component={LogBook} />}
          />
            <Route
            path="/exammarks"
            element={<LoadComponent component={ExamMarksPage} />}
          />

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    );
  } else if (AccessToken && UserDetails && RoleDetails === "STAFF") {
    return (
      <Routes>
        <Route to="/" element={<Layout />}>
          <Route
            path="/dashboard"
            element={<LoadComponent component={StaffDashboard} />}
          />
           <Route
            path="/staffhealthrecord"
            element={<LoadComponent component={staffhealthrecord} />}
          />
          <Route
            path="/stafflogbook"
            element={<LoadComponent component={LogBook} />}
          />
            <Route
            path="/staffexammarks"
            element={<LoadComponent component={AddExamMarksPage} />}
          />
           <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    );
  } else if (AccessToken && UserDetails && RoleDetails === "ADMIN") {
    return (
      <Routes>
        <Route to="/" element={<Layout />}>
          <Route
            path="/dashboard"
            element={<LoadComponent component={ApproveStudent} />}
          />
            
           <Route
            path="/adminlogbook"
            element={<LoadComponent component={LogBook} />}
          />
            <Route
            path="/approveexammarks"
            element={<LoadComponent component={AdminExamarks} />}
          />
           <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="" element={<Navigate to="/account/login" />} />
          <Route path="*" element={<Navigate to="/account/login" />} />
          <Route
            path="/account/login"
            element={<LoadComponent component={Login} />}
          />

          <Route
            path="/account/logout"
            element={<LoadComponent component={Logout} />}
          />
        </Route>
      </Routes>
    );
  }
};

export default AllRoutes;
