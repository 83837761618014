//External Lib Import
import { createSlice } from "@reduxjs/toolkit";
import SessionHelper from "../../helpers/SessionHelper";

const UserSlice = createSlice({
  name: "User",
  initialState: {
    UserDetails: SessionHelper.GetUserDetails() || undefined,
    RoleDetails: SessionHelper.GetRoleDetails() || undefined,
  },
  reducers: {
    SetUserDetails(state, action) {
      SessionHelper.SetUserDetails(action.payload);
      state.UserDetails = SessionHelper.GetUserDetails() || undefined;
    },
    SetRoleDetails(state, action) {
      SessionHelper.SetRoleDetails(action.payload);
      state.RoleDetails = SessionHelper.GetRoleDetails() || undefined;
    },
    RemoveUserDetails(state, action) {
      SessionHelper.RemoveUserDetails();
      state.UserDetails = SessionHelper.GetUserDetails() || undefined;
    },
    RemoveRoleDetails(state, action) {
      SessionHelper.RemoveRoleDetails();
      state.UserDetails = SessionHelper.GetRoleDetails() || undefined;
    },
  },
});

export const {
  SetUserDetails,
  RemoveUserDetails,
  SetRoleDetails,
  RemoveRoleDetails,
} = UserSlice.actions;
export default UserSlice.reducer;
